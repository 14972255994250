import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import VeeValidate from 'vee-validate'
import "regenerator-runtime/runtime"
import vuetify from '@/plugins/vuetify'

import setupInterceptors from './services/setupInterceptors'

Vue.config.performance = true
Vue.use(VeeValidate, { fieldsBagName: 'veeFields' })
Vue.use(CoreuiVue)

setupInterceptors(store)

Vue.prototype.$log = console.log.bind(console)

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  vuetify,
  components: {
    App
  }
})
