import api from "./api";
import TokenService from "./token.service";
import UserService from '@/services/user.service'

class AuthService {
  login({ username, password }) {
    return api
      .post("/oauth/token", {
        grant_type: 'password',
        client_id: 1,
        client_secret: 'K0JiA9TdF6DJkQl27m5vTkDTuovEvu3lqqfXbMZ9',
        username: username,
        password: password
      })
      .then((response) => {
        if (response.data.access_token) {
          TokenService.setUser(response.data)
          return response.data
        }
      });
  }

  parseJwt(token) {
    var base64Payload = token.split('.')[1];
    var payload = Buffer.from(base64Payload, 'base64');
    return JSON.parse(payload.toString());
  }

  setUserData(token) {
    UserService.getItem(parseJwt(token).id).then(
      (response) => {
        localStorage.setItem("user_auth", JSON.stringify(response.data));
        return response.data
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.loading = false;
      }
    );
  }

  logout() {
    TokenService.removeUser();
  }

  register({ username, email, password }) {
    return api.post("/auth/signup", {
      username,
      email,
      password
    });
  }
}

export default new AuthService();
