import api from './api';

class UserService {
  getItems(cadenaBusqueda) {
    cadenaBusqueda = cadenaBusqueda ? cadenaBusqueda : ''
    console.log('Cadena desde service')
    console.log(`/api/users${cadenaBusqueda}`)
    return api.get(`/api/users${cadenaBusqueda}`);
  }

  getItem(id) {
    return api.get(`/api/users/${id}`);
  }

  newItem(params) {
    return api.post(`/api/users/`, params)
  }

  editItem(params) {
    return api.put(`/api/users/${params.id}`, params)
  }
  
  recuperarClave(params) {
    return api.post(`/api/users/recover/password`, params)
  }

  asignarClave(params) {
    return api.post(`/api/users/assign/password`, params)
  }
}

export default new UserService();

// vscode://vscode.github-authentication/did-authenticate?windowid=7&code=d013287e2b5ce34b7879&state=6613ed4e-4eec-4e8f-be4f-56c4ad1dfc4a