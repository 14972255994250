import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from './store/auth.module'

Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  permissions: [],
  roles: []
}

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state, [variable, value]) {
    state[variable] = value
  }
}

const actions = {
  commitPermissionsFromSessionStorage ({ commit }) {
    let user_auth = JSON.parse(localStorage.getItem("user_auth"))

    let permissions = []

    if (user_auth) {
      commit('set', ['roles', user_auth.roles])
      for (let i = 0; i < user_auth.roles.length; i++) {
        user_auth.roles[i].permissions = user_auth.roles[i].permissions.map(x => x.name)
        for (let j = 0; j < user_auth.roles[i].permissions.length; j++) {
          permissions.push(user_auth.roles[i].permissions[j])
        }
      }
      commit('set', ['permissions', permissions])
    }
  }
}

const modules = {
  auth
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules
})