<template>
    <v-app>
      <v-main>
        <router-view></router-view>
      </v-main>
    </v-app>
</template>

<script>
import EventBus from "./common/EventBus"

export default {
  name: 'App',
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/pages/login');
    }
  },
  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
  },
  beforeDestroy() {
    EventBus.remove("logout");
  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';

  .c-sidebar-nav, .c-sidebar-nav-dropdown-items {
    padding-left: 0px !important;
  }
</style>
