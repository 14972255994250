import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')
const CrearEditarUser = () => import('@/views/users/CrearEditar')


const Archivos = () => import('@/views/archivos/Archivos')
const CargarArchivo = () => import('@/views/archivos/CargarArchivo')

// Reportes
const Comdac = () => import('@/views/reportes/Comdac')
const Desincorporacion = () => import('@/views/reportes/Desincorporacion')
const TaxonomiaActivosFijos = () => import('@/views/reportes/TaxonomiaActivosFijos')
const PanoramicoActivosFijos = () => import('@/views/reportes/PanoramicoActivosFijos')
const PanoramaValidacionInventariosOpAsociadas = () => import('@/views/reportes/PanoramaValidacionInventariosOpAsociadas')
const AtencionSolicitudes2021 = () => import('@/views/reportes/AtencionSolicitudes2021')
const GestorConsolidadoSolicitudes = () => import('@/views/reportes/GestorConsolidadoSolicitudes')
const DiagnosticoCalidad = () => import('@/views/reportes/DiagnosticoCalidad')
const Incorporaciones = () => import('@/views/reportes/Incorporaciones')
const Vitrina = () => import('@/views/reportes/Vitrina')

// Layouts
const Layouts = () => import('@/views/layout/Layouts')
const Layout = () => import('@/views/layout/Layout')
const CrearEditarLayout = () => import('@/views/layout/CrearEditar')

// Campos
const Campos = () => import('@/views/campos/Campos')
const CrearEditarCampo = () => import('@/views/campos/CrearEditar')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')
const RecuperarClave = () => import('@/views/pages/RecuperarClave')
const AsignarClave = () => import('@/views/pages/AsignarClave')

// Roles
const Roles = () => import('@/views/roles/Roles')
const CrearEditarRol = () => import('@/views/roles/CrearEditar')

// Control de Ejecuciones
const Ejecuciones = () => import('@/views/ControlEjecuciones/ControlEjecuciones')

// Temas
const Listas = () => import('@/views/listas/Listas')
const CrearEditarLista = () => import('@/views/listas/CrearEditar')

// Variables
const Variables = () => import('@/views/variables/Variables')
const CrearEditarVariable = () => import('@/views/variables/CrearEditar')

// Relaciones
const Relaciones = () => import('@/views/archivos/Relaciones')
const CrearEditarRelacion = () => import('@/views/archivos/CrearEditarRelacion')

// Consolidado Archivos
const ConsolidadoArchivos = () => import('@/views/archivos/ConsolidadoArchivos')
const CrearEditarConsolidadoArchivo = () => import('@/views/archivos/CrearEditarConsolidadoArchivo')
const EjecutarConsolidado = () => import('@/views/archivos/EjecutarConsolidado')

// Reglas
const Reglas = () => import('@/views/reglasProcesoCalidad/Reglas')
const CrearEditarRegla = () => import('@/views/reglasProcesoCalidad/CrearEditar')

// Procesos de Calidad
const ProcesosCalidad = () => import('@/views/procesosCalidad/Procesos')
const CrearEditarProcesoCalidad = () => import('@/views/procesosCalidad/CrearEditar')

// Ejecuciones Procesos de Calidad
const EjecutarProcesoCalidad = () => import('@/views/ejecucionesProcesoCalidad/Ejecutar')
const EjecucionesProcesoCalidad = () => import('@/views/ejecucionesProcesoCalidad/Ejecuciones')

// Pasos Procesos de Calidad
const PasosProcesoCalidad = () => import('@/views/pasosProcesoCalidad/Pasos')
const CrearEditarPasoProcesoCalidad = () => import('@/views/pasosProcesoCalidad/CrearEditar')


// Reglas Conciliador
const ReglasConciliador = () => import('@/views/reglasConciliador/Reglas')
const CrearEditarReglaConciliador = () => import('@/views/reglasConciliador/CrearEditar')

// Procesos Conciliador
const ProcesosConciliador = () => import('@/views/procesosConciliador/Procesos')
const CrearEditarProcesoConciliador = () => import('@/views/procesosConciliador/CrearEditar')

// Ejecuciones Procesos Conciliador
const EjecutarProcesoConciliador = () => import('@/views/ejecucionesProcesoConciliador/Ejecutar')
const EjecucionesProcesoConciliador = () => import('@/views/ejecucionesProcesoConciliador/Ejecuciones')

// Pasos Procesos Conciliador
const PasosProcesoConciliador = () => import('@/views/pasosProcesoConciliador/Pasos')
const CrearEditarPasoProcesoConciliador = () => import('@/views/pasosProcesoConciliador/CrearEditar')


// Autogestor
const DefinicionConsulta = () => import('@/views/autogestor/DefinicionConsulta')
const CrearEditarDefinicionConsulta = () => import('@/views/autogestor/CrearEditarDefinicionConsulta')
const RolesAccesoDefinicionConsulta = () => import('@/views/autogestor/RolesAccesoDefinicionConsulta')
const CategoriasConsultas = () => import('@/views/autogestor/Categorias')
const Consultas = () => import('@/views/autogestor/Consultas')
const Consulta = () => import('@/views/autogestor/Consulta')
const Consulta2 = () => import('@/views/autogestor/Consulta2')
const ConsultaURLAuxiliar = () => import('@/views/autogestor/ConsultaURLAuxiliar')
const ConsultaURLTickets = () => import('@/views/autogestor/ConsultaURLTickets')




// Registro Fotográfico
const RegistrosFotograficos = () => import('@/views/registroFotografico/RegistrosFotograficos')
const RegistrosFotograficosVitrina = () => import('@/views/registroFotografico/RegistrosFotograficosVitrina')
const CargasRegistros = () => import('@/views/registroFotografico/CargasRegistros')
const CargarRegistros = () => import('@/views/registroFotografico/Cargar')
const TrasladarRegistros = () => import('@/views/registroFotografico/TrasladarRegistros')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'cargue-archivos',
          name: 'Cargue de Archivos',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'layouts',
              meta: {
                label: 'Layouts'
              },
              component: {
                render(c) {
                  return c('router-view')
                }
              },
              children: [
                {
                  path: '',
                  name: 'Lista de Layouts',
                  component: Layouts
                },
                {
                  path: 'editar',
                  name: 'Editar Layout',
                  component: CrearEditarLayout
                },
                {
                  path: 'layout',
                  meta: {
                    label: 'Layout Details'
                  },
                  name: 'Layout',
                  component: Layout
                },
                {
                  path: 'campos',
                  meta: {
                    label: 'Campos'
                  },
                  component: {
                    render(c) {
                      return c('router-view')
                    }
                  },
                  children: [
                    {
                      path: '',
                      name: 'Lista de Campos',
                      component: Campos
                    },
                    {
                      path: 'editar',
                      name: 'Editar Campo',
                      component: CrearEditarCampo
                    }
                  ]
                },
                {
                  path: 'archivos',
                  meta: {
                    label: 'Archivos'
                  },
                  component: {
                    render(c) {
                      return c('router-view')
                    }
                  },
                  children: [
                    {
                      path: '',
                      name: 'Lista de Archivos',
                      component: Archivos
                    },
                    {
                      path: 'cargar',
                      name: 'Cargar Archivo',
                      component: CargarArchivo
                    }
                  ]
                },
              ]
            },
            {
              path: 'variables',
              meta: {
                label: 'Variables'
              },
              component: {
                render(c) {
                  return c('router-view')
                }
              },
              children: [
                {
                  path: '',
                  name: 'Lista de Variables',
                  component: Variables
                },
                {
                  path: 'editar',
                  name: 'Editar Variable',
                  component: CrearEditarVariable
                }
              ]
            },
            {
              path: 'relaciones',
              meta: {
                label: 'Relaciones'
              },
              component: {
                render(c) {
                  return c('router-view')
                }
              },
              children: [
                {
                  path: '',
                  name: 'Relaciones',
                  component: Relaciones
                },
                {
                  path: 'editarrelacion',
                  name: 'Editar Relación',
                  component: CrearEditarRelacion
                }
              ]
            },
            {
              path: 'consolidado-archivos',
              meta: {
                label: 'Consolidado Archivos'
              },
              component: {
                render(c) {
                  return c('router-view')
                }
              },
              children: [
                {
                  path: '',
                  name: 'consolidadoArchivos',
                  component: ConsolidadoArchivos
                },
                {
                  path: 'editarconsolidadoarchivo',
                  name: 'Editar Consolidado Archivo',
                  component: CrearEditarConsolidadoArchivo
                },
                {
                  path: 'ejecutarconsolidadoarchivo',
                  name: 'Ejecutar Consolidado Archivo',
                  component: EjecutarConsolidado
                }
              ]
            },
          ]
        },
        {
          path: 'calidad',
          name: 'Calidad',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'reglas',
              meta: {
                label: 'Reglas'
              },
              component: {
                render(c) {
                  return c('router-view')
                }
              },
              children: [
                {
                  path: '',
                  name: 'Lista de Reglas',
                  component: Reglas
                },
                {
                  path: 'editar',
                  name: 'Editar Regla',
                  component: CrearEditarRegla
                }
              ]
            },
            {
              path: 'procesos-calidad',
              meta: {
                label: 'Procesos de Calidad'
              },
              component: {
                render(c) {
                  return c('router-view')
                }
              },
              children: [
                {
                  path: '',
                  name: 'Lista de Procesos de Calidad',
                  component: ProcesosCalidad
                },
                {
                  path: 'editar',
                  name: 'Editar Proceso de Calidad',
                  component: CrearEditarProcesoCalidad
                },
                {
                  path: 'ejecutar',
                  name: 'Ejecutar Proceso de Calidad',
                  component: EjecutarProcesoCalidad
                },
                {
                  path: 'ejecuciones',
                  meta: {
                    label: 'Ejecuciones'
                  },
                  component: {
                    render(c) {
                      return c('router-view')
                    }
                  },
                  children: [
                    {
                      path: '',
                      name: 'Lista de Ejecuciones',
                      component: EjecucionesProcesoCalidad
                    }
                  ]
                },
                {
                  path: 'pasos',
                  meta: {
                    label: 'Pasos'
                  },
                  component: {
                    render(c) {
                      return c('router-view')
                    }
                  },
                  children: [
                    {
                      path: '',
                      name: 'Lista de Pasos',
                      component: PasosProcesoCalidad
                    },
                    {
                      path: 'editar',
                      name: 'Editar Paso',
                      component: CrearEditarPasoProcesoCalidad
                    }
                  ]
                },
              ]
            },
          ]
        },
        {
          path: 'conciliador',
          name: 'Conciliador',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'reglas',
              meta: {
                label: 'Reglas'
              },
              component: {
                render(c) {
                  return c('router-view')
                }
              },
              children: [
                {
                  path: '',
                  name: 'Lista de Reglas Conciliador',
                  component: ReglasConciliador
                },
                {
                  path: 'editar',
                  name: 'Editar Regla Conciliador',
                  component: CrearEditarReglaConciliador
                }
              ]
            },
            {
              path: 'procesos-conciliador',
              meta: {
                label: 'Procesos Conciliador'
              },
              component: {
                render(c) {
                  return c('router-view')
                }
              },
              children: [
                {
                  path: '',
                  name: 'Lista de Procesos Conciliador',
                  component: ProcesosConciliador
                },
                {
                  path: 'editar',
                  name: 'Editar Proceso Conciliador',
                  component: CrearEditarProcesoConciliador
                },
                {
                  path: 'ejecutar',
                  name: 'Ejecutar Proceso Conciliador',
                  component: EjecutarProcesoConciliador
                },
                {
                  path: 'ejecuciones',
                  meta: {
                    label: 'Ejecuciones'
                  },
                  component: {
                    render(c) {
                      return c('router-view')
                    }
                  },
                  children: [
                    {
                      path: '',
                      name: 'Lista de Ejecuciones',
                      component: EjecucionesProcesoConciliador
                    }
                  ]
                },
                {
                  path: 'pasos',
                  meta: {
                    label: 'Pasos'
                  },
                  component: {
                    render(c) {
                      return c('router-view')
                    }
                  },
                  children: [
                    {
                      path: '',
                      name: 'Lista de Pasos',
                      component: PasosProcesoConciliador
                    },
                    {
                      path: 'editar',
                      name: 'Editar Paso',
                      component: CrearEditarPasoProcesoConciliador
                    }
                  ]
                },
              ]
            },
          ]
        },
        {
          path: 'autogestor',
          name: 'Autogestor Consultas Auxiliar',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'gestor-consultas',
              meta: {
                label: 'Gestor de Contultas'
              },
              component: {
                render(c) {
                  return c('router-view')
                }
              },
              children: [
                {
                  path: '',
                  name: 'Definicion Consulta',
                  component: DefinicionConsulta
                },
                {
                  path: 'editar',
                  name: 'Editar',
                  component: CrearEditarDefinicionConsulta
                },
                {
                  path: 'roles-acceso',
                  name: 'Roles de acceso',
                  component: RolesAccesoDefinicionConsulta
                },
              ]
            },
            {
              path: 'categorias',
              meta: {
                label: 'Categorias'
              },
              component: {
                render(c) {
                  return c('router-view')
                }
              },
              children: [
                {
                  path: '',
                  name: 'Categorias',
                  component: CategoriasConsultas
                },
                {
                  path: 'consultas',
                  meta: {
                    label: 'Consultas'
                  },
                  component: {
                    render(c) {
                      return c('router-view')
                    }
                  },
                  children: [
                    {
                      path: '',
                      name: 'Consultas',
                      component: Consultas
                    },
                    {
                      path: 'consulta',
                      name: 'Consulta',
                      component: Consulta2
                    },
                    {
                      path: 'consulta-test',
                      name: 'Consulta Test',
                      component: Consulta2
                    },
                    {
                      path: 'consulta-url-auxiliar',
                      name: 'Consulta Auxiliar',
                      component: ConsultaURLAuxiliar
                    },
                    {
                      path: 'consulta-url-tickets',
                      name: 'Consulta Tickets',
                      component: ConsultaURLTickets
                    },
                  ]
                },
                {
                  path: 'consulta',
                  name: 'Consulta',
                  component: Consulta2
                }
              ]
            },
          ]
        },
        {
          path: 'repositorio-fotografico',
          name: 'Repositorio Fotográfico',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'consulta',
              meta: {
                label: 'Consulta'
              },
              component: {
                render(c) {
                  return c('router-view')
                }
              },
              children: [
                {
                  path: '',
                  name: 'Registros Fotográficos',
                  component: RegistrosFotograficos
                },
              ]
            },
            {
              path: 'vitrina',
              meta: {
                label: 'Vitrina'
              },
              component: {
                render(c) {
                  return c('router-view')
                }
              },
              children: [
                {
                  path: '',
                  name: 'Vitrina',
                  component: RegistrosFotograficosVitrina
                },
              ]
            },
            {
              path: 'cargas',
              name: 'Cargas de Registros',
              component: {
                render(c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  name: 'Lista de Cargas',
                  component: CargasRegistros
                },
                {
                  path: 'nueva-carga',
                  name: 'Cargar',
                  component: CargarRegistros
                }
              ]
            },
            {
              path: 'trasladar',
              name: 'Trasladar Registros',
              component: TrasladarRegistros
            },
          ]
        },
        {
          path: 'users',
          meta: {
            label: 'Usuarios'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Lista de Usuarios',
              component: Users
            },
            {
              path: 'editar',
              name: 'Editar Usuario',
              component: CrearEditarUser
            },
            {
              path: 'user',
              meta: {
                label: 'Detalles del Usuario'
              },
              name: 'User',
              component: User
            }
          ]
        },
        {
          path: 'roles',
          meta: {
            label: 'Roles'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Lista de Roles',
              component: Roles
            },
            {
              path: 'editar',
              name: 'Editar Rol',
              component: CrearEditarRol
            }
          ]
        },
        {
          path: 'ejecuciones',
          meta: {
            label: 'Control de Ejecuciones'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Lista de Ejecuciones',
              component: Ejecuciones
            }
          ]
        },
        {
          path: 'listas',
          meta: {
            label: 'Listas'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Lista de Listas',
              component: Listas
            },
            {
              path: 'editar',
              name: 'Editar Lista',
              component: CrearEditarLista
            }
          ]
        },
        {
          path: 'reportes',
          //   redirect: '/reportes/comdac',
          name: 'Reportes',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'comdac',
              name: 'Comdac',
              component: Comdac
            },
            {
              path: 'desincorporacion',
              name: 'Desincorporación',
              component: Desincorporacion
            },
            {
              path: 'taxonomia-activos-fijos',
              name: 'Taxonomía de activos fijos',
              component: TaxonomiaActivosFijos
            },
            {
              path: 'panoramico-activos-fijos',
              name: 'Panoramico de activos fijos',
              component: PanoramicoActivosFijos
            },
            {
              path: 'panorama-validacion-inventarios-op-asociadas',
              name: 'Panorama gestión validacion de inventarios op. asociadas',
              component: PanoramaValidacionInventariosOpAsociadas
            },
            {
              path: 'atencion-solicitudes-2021',
              name: 'Atención de solicitudes 2021',
              component: AtencionSolicitudes2021
            },
            {
              path: 'gestor-consolidado-solicitudes',
              name: 'Informe gestor consolidado de solicitudes',
              component: GestorConsolidadoSolicitudes
            },
            {
              path: 'diagnostico-calidad',
              name: 'Diagnóstico de calidad',
              component: DiagnosticoCalidad
            },
            {
              path: 'incorporaciones',
              name: 'Incorporaciones',
              component: Incorporaciones
            },
            {
              path: 'vitrina',
              name: 'Vitrina',
              component: Vitrina
            }
          ]
        }
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        },
        {
          path: 'recuperar-clave',
          name: 'Recuperar clave de acceso',
          component: RecuperarClave
        },
        {
          path: 'asignar-clave/:correo_electronico/:token_verificacion',
          name: 'Asignar nueva clave de acceso',
          component: AsignarClave
        }
      ]
    }
  ]
}

